import React from 'react';
import './Section4.css'; // Import your CSS

const testimonials = [
    {
        text: "Froxon transformed our online presence!",
        author: "Happy Client",
    },
    {
        text: "Their service exceeded our expectations!",
        author: "Satisfied Customer",
    },
    {
        text: "A fantastic team that delivers results!",
        author: "Grateful Client",
    },
];

const Section4 = () => {
    return (
        <section id="section4">
            <h2>Testimonials</h2>
            <div className="testimonial-container">
                {testimonials.map((testimonial, index) => (
                    <blockquote key={index}>
                        <p>"{testimonial.text}"</p>
                        <cite>- {testimonial.author}</cite>
                    </blockquote>
                ))}
            </div>
        </section>
    );
};

export default Section4;
