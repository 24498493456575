import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer py-10 bg-gray-800 text-white">
            <div className="container mx-auto px-4">
                <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
                    

                    <div>
                        <p className="section-title">Froxon</p>
                        <ul className="links mt-4">
                            {["About Us", "Careers", "Press", "Blog"].map(item => (
                                <li key={item}>
                                    <a href="#" className="hover:text-blue-400">{item}</a>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div>
                        <p className="section-title">Resources</p>
                        <ul className="links mt-4">
                            {["Documentation", "API Reference", "Community", "Support"].map(item => (
                                <li key={item}>
                                    <a href="#" className="hover:text-blue-400">{item}</a>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div>
                        <p className="section-title">Connect</p>
                        <ul className="links mt-4">
                            {["Contact", "Newsletter", "Privacy Policy", "Terms of Service"].map(item => (
                                <li key={item}>
                                    <a href="#" className="hover:text-blue-400">{item}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                <hr className="my-8 border-gray-600" />

                <p className="footer-text text-center">© 2024, All Right by Froxon</p>
            </div>
        </footer>
    );
}

export default Footer;
