import React, { useEffect, useState } from 'react';
import './Section1.css'; // Import your CSS

const Section1 = () => {
    const [time, setTime] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(new Date());
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const hour = time.getHours() % 12;
    const minute = time.getMinutes();
    const second = time.getSeconds();

    const hourRotation = hour * 30 + minute * 0.5;
    const minuteRotation = minute * 6;
    const secondRotation = second * 6;

    return (
        <section id="section1" className="about-section">
            <div className="content">
                <div className="text-side">
                    <h2 className="fade-in">About Froxon</h2>
                    <p className="slide-in">
                        Froxon is a leading web and app development company dedicated to delivering innovative solutions tailored to your business needs.
                    </p>
                    <p className="slide-in">
                        With a focus on user experience and cutting-edge technology, we ensure that every project is completed to the highest standard.
                    </p>
                </div>
                <div className="image-side">
                    <div className="clock-container">
                        <div className="clock">
                            <div className="hand hour" style={{ transform: `rotate(${hourRotation}deg)` }}></div>
                            <div className="hand minute" style={{ transform: `rotate(${minuteRotation}deg)` }}></div>
                            <div className="hand second" style={{ transform: `rotate(${secondRotation}deg)` }}></div>
                            {/* Add numbers 1-12 */}
                            {[...Array(12)].map((_, index) => {
                                const angle = index * 30;
                                return (
                                    <div
                                        key={index}
                                        className="clock-number"
                                        style={{
                                            transform: `rotate(${angle}deg) translateY(-90px)`,
                                        }}
                                    >
                                        {index + 1}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Section1;
