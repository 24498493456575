import React from 'react';
import './Section2.css'; // Import the CSS


const Section2 = () => {
    return (
        <section id="section2" className="services-section">
            <h2 className="section-title">Our Services</h2>
            <div className="services-container">
                <div className="service-item">
                    <h3>Web Development</h3>
                    <p>Building responsive and scalable web applications tailored to your business needs.</p>
                </div>
                <div className="service-item">
                    <h3>UI/UX Design</h3>
                    <p>Creating intuitive and user-friendly designs that enhance user experience.</p>
                </div>
                <div className="service-item">
                    <h3>SEO Optimization</h3>
                    <p>Improving your website's visibility on search engines to attract more traffic.</p>
                </div>
            </div>
            
        </section>
    );
};

export default Section2;
