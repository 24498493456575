import React, { useState } from 'react';
import { Button, Offcanvas } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import './Navbar.css'; // Your CSS file for additional styles
import logo from '../assets/logo.png'; // Adjust path to your logo

const Navbar = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <nav className="navbar">
                <div className="logo">
                    <img src={logo} alt="Froxon Logo" />
                </div>
                <Button variant="link" onClick={handleShow} className="menu-toggle">
                    <FontAwesomeIcon icon={faBars} size="lg" />
                </Button>
            </nav>

            <Offcanvas show={show} onHide={handleClose} placement="start">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Froxon</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <ul className="nav-links">
                        <li><a href="#section1" onClick={handleClose}>Home</a></li>
                        <li><a href="#section2" onClick={handleClose}>About</a></li>
                        <li><a href="#section3" onClick={handleClose}>Services</a></li>
                        <li><a href="#section4" onClick={handleClose}>Portfolio</a></li>
                        <li><a href="#section5" onClick={handleClose}>Contact</a></li>
                    </ul>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};

export default Navbar;
