import React from 'react';
import './Section5.css'; // Import your CSS

const Section5 = () => {
    return (
        <section id="section5">
            <h2>Contact Us</h2>
            <form>
                <input type="text" placeholder="Your Name" required />
                <input type="email" placeholder="Your Email" required />
                <textarea placeholder="Your Message" required></textarea>
                <button type="submit">Send Message</button>
            </form>
        </section>
    );
};

export default Section5;
