import React from 'react';
import project1 from '../assets/webdevelopment.webp';
import project2 from '../assets/appdevelopment.webp';
import project3 from '../assets/testindeveloment.png';
import './Section3.css'; // Import your CSS

const Section3 = () => {
    return (
        <section id="section3">
            <h2>Our Work</h2>
            <div className="portfolio">
                <img src={project1} alt="Project 1" />
                <img src={project2} alt="Project 2" />
                <img src={project3} alt="Project 3" />
            </div>
        </section>
    );
};

export default Section3;
