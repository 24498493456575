import React from 'react';
import './Header.css';

const Header = () => {
    return (
        <header className="header">
            <h1>Welcome to Froxon</h1>
            <p>Your solution for innovative web technologies.</p>
            <button>Get Started</button>
        </header>
    );
};

export default Header;
